// Phone number blocks are separated by spaces or dashes
const phoneSeparator = '(\\s*|-|\\.)';
// Domestic phone numbers are three blocks XXX-XXX-XXXX with optional parens around the first block
const domesticPhoneRegex = `\\(?[0-9]{3}\\)?${phoneSeparator}[0-9]{3}${phoneSeparator}[0-9]{4}$`;
// US phone numbers have country code 1
const usPhoneRegex = new RegExp(`^\\+?1?${phoneSeparator}${domesticPhoneRegex}`);

const phoneValidation = (value: any) => {
  if (typeof value !== 'string' || !value.trim()) return true;

  return usPhoneRegex.test(value);
};

const nameValidation = (value: any) => typeof value === 'string' && value.trim().split(' ').length >= 2;

// Duplicate same validation from our API.
// Github: apartmentlist/blob/main/app/validators/email_validator.rb
const emailValidation = (value: any) => {
  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^([\w\.%\+\-]+)@([\w\-]+\.)+([\w]{2,})$/i;
  return typeof value === 'string' && emailRegex.test(value);
};

const subjectValidation = (value: any) => typeof value === 'string' && value.length > 0;
const messageValidation = (value: any) => typeof value === 'string' && value.length > 0;

export type ValidationName = 'Name' | 'Email' | 'Phone' | 'Subject' | 'Message';
interface Validation {
  check: (value: string) => boolean;
  message: string;
}

export type Validations = {
  [key in ValidationName]: Validation;
};

export const validations = {
  Name: {
    check: nameValidation,
    message: 'Please enter your name.',
  },
  Email: {
    check: emailValidation,
    message: 'Please enter an email address.',
  },
  Phone: {
    check: phoneValidation,
    message: 'Please enter a valid phone number.',
  },
  Subject: {
    check: subjectValidation,
    message: 'Please enter a subject',
  },
  Message: {
    check: messageValidation,
    message: 'Please enter a message',
  },

} as Validations;
