import React, { useState } from 'react';
import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Email';
import styled from '@emotion/styled';
import { useForm } from '@formspree/react';

import { Markdown } from '../types/Markdown';
import { ValidationName, validations } from './validations';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.primary.main,
  },
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const Success = styled.div`
  font-size: 36px;
`

interface PropTypes {
  copy: Markdown;
  success: string;
}

interface Field {
  required: boolean;
  rows?: number;
}

const fields = {
  Name: { required: true },
  Email: { required: true },
  Phone: { required: false },
  Subject: { required: true },
  Message: { required: true, rows: 4 },
} as StringKeyable<Field>;

function ContactContainer({ copy, success }: PropTypes) {
  const [state, handleSubmit] = useForm('xyyonbwn');
  const [values, setValues] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Subject: '',
    Message: '',
  });
  const [errors, setErrors] = useState({
    Name: '',
    Email: '',
    Phone: '',
    Subject: '',
    Message: '',
  });

  function onSubmit(e) {
    e.preventDefault();
    console.log('submitting');
    if (isValid()) {
      handleSubmit(e);
    }
  }

  function isValid() {
    let valid = true;
    const newErrors = errors;
    if (!validations.Name.check(values.Name)) {
      valid = false;
      newErrors.Name = validations.Name.message;
    }
    if (!validations.Email.check(values.Email)) {
      valid = false;
      newErrors.Email = validations.Email.message;
    }
    if (!validations.Subject.check(values.Subject)) {
      valid = false;
      newErrors.Subject = validations.Subject.message;
    }
    if (!validations.Message.check(values.Message)) {
      valid = false;
      newErrors.Message = validations.Message.message;
    }
    if (values.Phone.length > 0 && !validations.Phone.check(values.Phone)) {
      valid = false;
      newErrors.Phone = validations.Phone.message;
    }
    console.log('setErrors', newErrors);
    setErrors(newErrors);

    return valid;
  }

  function onBlurValidate(e: React.FocusEvent<HTMLInputElement>) {
    const name = e.target.name as ValidationName;
    const valid = validations[name].check(values[e.target.name]);

    console.log('onBlur', name, valid)
    setErrors(prevErrors => {
      return { ...prevErrors, [name]: valid ? '' : validations[name].message };
    });
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  }

  function renderTextField(name: string) {
    const field = fields[name];

    let helperText = field.required ? '' : 'Optional';
    if (errors[name].length > 0) {
      helperText = errors[name];
    }

    return (
      <StyledTextField
        id={name}
        error={errors[name].length > 0}
        fullWidth
        label={name}
        helperText={helperText}
        multiline={!!field.rows}
        name={name}
        onBlur={onBlurValidate}
        onChange={handleChange}
        required={field.required}
        rows={field.rows}
        value={values[name]}
      />
    );
  }

  return (
    <Paper sx={{ bgcolor: 'white', border: 1, color: 'primary.main', opacity: 0.8, p: 4 }}>
      {state.succeeded ?
        <Success>{success}</Success>
      :
      <>
        <Box dangerouslySetInnerHTML={{ __html: copy.childMarkdownRemark.html }} sx={{ pb: 2 }} />
        <Box component='form' autoComplete='off' noValidate onSubmit={onSubmit}>
          <Grid container columnSpacing={1} rowSpacing={4}>
            <Grid container item xs={12} spacing={2}>
              <Grid item xs={12} md={4}>
                {renderTextField('Name')}
              </Grid>
              <Grid item xs={12} md={4}>
                {renderTextField('Email')}
              </Grid>
              <Grid item xs={12} md={4}>
                {renderTextField('Phone')}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {renderTextField('Subject')}
            </Grid>
            <Grid item xs={12}>
              {renderTextField('Message')}
            </Grid>
          </Grid>
          <Box sx={{ pt: 3, width: 200 }}>
            <Button disabled={state.submitting} endIcon={<SendIcon />} fullWidth size='large' type='submit' variant='contained'>
              Send
            </Button>
          </Box>
        </Box>
        </>
      }
    </Paper>
  );
}

export default ContactContainer;
