import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';
import ContactContainer from '../components/ContactContainer';

const ContactPage = () => (
  <StaticQuery
    query={graphql`
      query ContactPageQuery {
        allContentfulContactPage {
          edges {
            node {
              copy {
                childMarkdownRemark {
                  html
                }
              }
              success
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout pageName='Contact'>
        <ContactContainer copy={data.allContentfulContactPage.edges[0].node.copy} success={data.allContentfulContactPage.edges[0].node.success} />
      </Layout>
    )}
  />
);

export default ContactPage;
